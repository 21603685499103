"use strict";

import Swiper from 'swiper';
import {Navigation, Pagination, Scrollbar} from 'swiper/modules';
import {
    closeAllPopups,
    disableAds,
} from "./general";

export const battlePopupId = 'battleLeaderboardPopup';
let battlePopupElement = undefined;

document.addEventListener("DOMContentLoaded", function (event) {
    let popupElement = null;
    let instantiated = false;

    showBattlePopup();
    initBattleCompetitorsSlider();
    initBattleEndPageCompetitorsSlider('endBattle')

    function showBattlePopup() {
        const modalShowButtons = document.querySelectorAll('[data-modal-battle-leaderboard]');
        modalShowButtons.forEach(function (item) {
            item.addEventListener('click', function (event) {
                event.preventDefault();
                closeAllPopups();
                disableAds();

                // not doing request again
                if (instantiated === true) {
                    popupElement.classList.remove('hidden');

                    return;
                }

                let leaderboardUrl = item.dataset.url;

                fetch(leaderboardUrl, {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                }).then(response => response.text())
                    .then(function (html) {
                        document.body.appendChild(document.createRange().createContextualFragment(html));
                        instantiated = true;

                        popupElement = document.getElementById(`${battlePopupId}`);
                        popupElement.classList.remove('hidden');

                        battleCountdowns();
                        initBattleCompetitorsSlider();
                    })
                    .catch(error => console.error('Error:', error));
            });
        })
    }

    function initBattleCompetitorsSlider() {
        new Swiper(`#${battlePopupId} .swiper`, {
            modules: [Navigation, Pagination, Scrollbar],
            slidesPerView: 4,
            direction: "vertical",
            autoHeight: true,
            spaceBetween: 12,
            scrollbar: {
                el: `#${battlePopupId} .swiper-scrollbar`,
                draggable: true,
            },
            breakpoints: {
                320: {
                    spaceBetween: 6,
                },
                640: {
                    spaceBetween: 12,
                },
            },
        });
    }

    function initBattleEndPageCompetitorsSlider(idSelector) {
        new Swiper(`#${idSelector} .swiper`, {
            modules: [Navigation, Pagination, Scrollbar],
            slidesPerView: 4,
            direction: "vertical",
            spaceBetween: 6,
            scrollbar: {
                el: `#${idSelector} .swiper-scrollbar`,
                draggable: true,
            },
        });
    }
});

function displayBattlePopupCountdown(message, leaderboardEnded, lessThanHour) {
    if (!battlePopupElement) {
        battlePopupElement = document.getElementById(`${battlePopupId}`);
    }

    if (battlePopupElement) {
        let popupBattleCountdownElement = battlePopupElement.querySelector('[data-battle-popup-timer]');
        let parentElement = popupBattleCountdownElement.parentElement.parentElement;
        let icon = parentElement.querySelector('svg');

        displayTimer(popupBattleCountdownElement, message);

        popupBattleCountdownElement.parentElement.parentElement.parentElement.classList.remove('opacity-0');

        if (true === leaderboardEnded) {
            parentElement.classList.remove('text-red-550', 'border-red-550');
            parentElement.classList.add('text-primary-200');
            icon.classList.remove('fill-red-600');
            icon.classList.add('fill-primary-200');

            return;
        }

        if (lessThanHour) {
            parentElement.classList.remove('bg-cyan-100');
            parentElement.classList.add('text-red-600', 'bg-red-50');
            icon.classList.remove('fill-primary-500');
            icon.classList.add('fill-red-600');
        }
    }
}

export function battleCountdowns() {
    const endPageCountdownElement = document.querySelector('[data-battle-end-date]');
    const headerTimerElement = document.querySelector('[data-battle-header-end-date]');
    let timerId = null;

    if (!endPageCountdownElement && !headerTimerElement) return;

    const timerEndDate = headerTimerElement.dataset.battleHeaderEndDate * 1000;
    const endMessage = headerTimerElement.dataset.endMessage;

    timerId = setInterval(() => {
        let {message, leaderboardEnded, lessThanHour, days, hours, minutes, seconds} = generateCountdownData(timerEndDate, endMessage);
        if (leaderboardEnded) {
            clearInterval(timerId);
        }

        if (headerTimerElement) {
            displayTimer(headerTimerElement, message);
            updateBattleCountdownWrapperStyles(headerTimerElement.parentElement.parentElement, leaderboardEnded, lessThanHour);
        }

        if (endPageCountdownElement) {
            displayTimerWithLabels(endPageCountdownElement, leaderboardEnded, lessThanHour, days, hours, minutes, seconds);
        }

        displayBattlePopupCountdown(message, leaderboardEnded, lessThanHour)
    }, 1000);
}

function generateCountdownData(timerEndDate, endMessage) {
    const now = new Date().getTime();
    const distance = timerEndDate - now;
    let leaderboardEnded = !!distance === false || distance < 0;

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    const lessThanHour = days === 0 && hours === 0 && minutes < 60;

    let message = String(hours).padStart(2, '0') + ":" + String(minutes).padStart(2, '0') + ":" + String(seconds).padStart(2, '0');
    if (days > 0) {
        message = String(days).padStart(2, '0') + ":" + message;
    }
    if (leaderboardEnded) {
        message = endMessage;
    }

    return {message, leaderboardEnded, lessThanHour, days, hours, minutes, seconds}
}

function updateBattleCountdownWrapperStyles(parentElement, leaderboardEnded, lessThanHour) {
    let icon = parentElement.querySelector('svg');
    parentElement.classList.remove('opacity-0');

    if (true === leaderboardEnded) {
        parentElement.classList.remove('border-transparent', 'text-red-550', 'border-red-550');
        parentElement.classList.add('text-gray-500', 'border-gray-500');

        icon.classList.remove('fill-red-550');
        icon.classList.add('fill-primary-100');

        return;
    }

    if (lessThanHour) {
        parentElement.classList.remove('border-transparent');
        parentElement.classList.add('text-red-550', 'border-red-550');
        icon.classList.add('fill-red-550');
    }
}

function displayTimer(element, message) {
    element.innerText = message;
}

function displayTimerWithLabels(endPageCountdownElement, leaderboardEnded, lessThanHour, days, hours, minutes, seconds) {
    let loader = endPageCountdownElement.querySelector('svg');
    let labelsElement = endPageCountdownElement.querySelector('[data-labels]');

    let daysLabelElement = labelsElement.querySelector('[data-label-days]');
    let daysLabel = daysLabelElement.querySelector('span');
    let hoursLabel = labelsElement.querySelector('[data-label-hours] span');
    let minutesLabel = labelsElement.querySelector('[data-label-minutes] span');
    let secondsLabel = labelsElement.querySelector('[data-label-seconds] span');

    if (true === leaderboardEnded) {
        daysLabelElement.classList.add('hidden');

        [daysLabel, hoursLabel, minutesLabel, secondsLabel].forEach((label) => {
            label.classList.remove('text-red-550', 'text-primary-500');
            label.innerText = String(0).padStart(2, '0');
        });
        loader.classList.add('hidden');
        labelsElement.classList.remove('hidden', 'text-red-550', 'text-primary-300');
        labelsElement.classList.add('text-primary-100');

        return;
    }

    if (days <= 0) {
        daysLabelElement.classList.add('hidden');

        if (lessThanHour) {
            [labelsElement, hoursLabel, minutesLabel, secondsLabel].forEach((label) => {
                label.classList.remove('text-primary-300');
                label.classList.add('text-red-550');
            });
        }
    }

    labelsElement.classList.add('text-primary-300');
    [daysLabel, hoursLabel, minutesLabel, secondsLabel].forEach((label) => {
        label.classList.add('text-primary-500');
    });
    daysLabel.innerText = String(days).padStart(2, '0');
    hoursLabel.innerText = String(hours).padStart(2, '0');
    minutesLabel.innerText = String(minutes).padStart(2, '0');
    secondsLabel.innerText = String(seconds).padStart(2, '0');

    loader.classList.add('hidden');
    labelsElement.classList.remove('hidden');
}
