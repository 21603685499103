"use strict";

import {disableAds, refreshAds} from "./general";

const mobileMenuToggle = document.querySelector('[data-menu-toggler]');
const mobileMenuContent = document.querySelector('[data-menu-content]');
const mobileMenuBackground = document.querySelector('[data-menu-background]');
const menuWidth = 'w-64';

mobileMenuListener();

function mobileMenuListener() {
    if (!mobileMenuToggle || !mobileMenuContent || !mobileMenuBackground) {
        return;
    }

    listeningClickOnBurgerButton();
    listeningAClickOnCategories();
    listeningAClickOutsideOfMenu();

    function toggleMenu() {
        mobileMenuContent.style.height = `${document.body.scrollHeight}px`;
        mobileMenuContent.classList.toggle(menuWidth);

        mobileMenuBackground.classList.toggle('w-full');
        mobileMenuBackground.classList.toggle('opacity-100');

        if (isMenuVisible()) {
            disableAds();
        } else {
            refreshAds();
        }
    }

    function listeningAClickOnCategories() {
        const childCategoriesToggle = document.querySelectorAll('[data-show-children]');

        childCategoriesToggle.forEach(function (element) {
            element.addEventListener('click', function (e) {
                e.preventDefault();
                const closestLink = e.target.closest('a')
                const closestDiv = e.target.closest('div')

                closestLink.classList.toggle('bg-cyan-50');
                closestDiv.querySelector("div").classList.toggle('hidden');
            });
        });
    }

    function listeningClickOnBurgerButton() {
        mobileMenuToggle.addEventListener('click', function (e) {
            e.preventDefault();
            toggleMenu();
        });
    }

    function listeningAClickOutsideOfMenu() {
        document.addEventListener('click', function (e) {
            if (isMenuVisible() && !mobileMenuContent.contains(e.target) && !mobileMenuToggle.contains(e.target)) {
                toggleMenu();
            }
        });
    }

    function isMenuVisible() {
        return mobileMenuContent.classList.contains(menuWidth);
    }
}

export function hideMenu() {
    mobileMenuContent.classList.remove(menuWidth);

    mobileMenuBackground.classList.remove('w-full');
    mobileMenuBackground.classList.remove('opacity-100');
}
