// import './bootstrap.js';
/*
 * Welcome to your app's main JavaScript file!
 *
 * This file will be included onto the page via the importmap() Twig function,
 * which should already be in your base.html.twig.
 */

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/grid';
import 'swiper/css/scrollbar';

import './styles/app.css';
import './styles/homepage.css';
import './styles/account.css';
import './styles/battle.css';

import './js/mobile-menu';
import './js/create-account-popup';

import {
    leaderboardTabs,
    accordionButtonsHandler,
    initContentSlider,
    privacyButtonsHandler,
    handleAdsVisibility,
    popupBackgroundClick,
    hidePopupHandler, sharePopupListener, copyLinkListener
} from "./js/general";
import {battleCountdowns} from "./js/battle";

document.addEventListener("DOMContentLoaded", function (event) {
    privacyButtonsHandler();
    leaderboardTabs();
    accordionButtonsHandler();
    battleCountdowns();
    initContentSlider('factLabSlider');
    initContentSlider('promotionSlider');
    initContentSlider('popularOnTheInternet');
    handleAdsVisibility();
    popupBackgroundClick();
    hidePopupHandler();
    sharePopupListener();
    copyLinkListener();
});
