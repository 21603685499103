"use strict";

import Swiper from "swiper";
import {Navigation, Pagination} from "swiper/modules";

export function closeAllPopups() {
    document.querySelectorAll('[data-popup]').forEach(item => item.classList.add('hidden'));
}

export function popupBackgroundClick() {
    document.addEventListener('click', function (event) {
        let target = event.target;
        if (target.hasAttribute('data-popup')) {
            refreshAds();
            closeAllPopups();
        }
    });
}

export function leaderboardTabs() {
    const activeTabClasses = ['bg-cyan-500', 'text-white'];

    document.addEventListener("click", function (event) {
        const target = event.target.closest("[data-leaderboard-tab]");

        if (target) {
            let leaderboardToActivate = document.getElementById(event.target.getAttribute('href').replace('#', ''));
            event.preventDefault();

            let parentNode = event.target.parentNode.parentNode;
            parentNode.querySelectorAll('[data-leaderboard-tab]').forEach(item => item.classList.remove(...activeTabClasses));
            event.target.classList.add(...activeTabClasses);

            parentNode.querySelectorAll('[data-leaderboards-list] > div').forEach(leaderboard => leaderboard.classList.add('hidden'));
            leaderboardToActivate.classList.remove('hidden');
            leaderboardToActivate.classList.add('block');
        }
    });
}

export function accordionButtonsHandler() {
    const accordionButtons = document.querySelectorAll('[data-accordion-target]');
    if (!accordionButtons) return;

    accordionButtons.forEach(button => {
        button.addEventListener('click', e => {
            e.preventDefault();

            const target = e.target.closest('span').dataset.accordionTarget;
            const targetDiv = document.getElementById(target);

            e.target.classList.toggle('rotate-180');

            if (targetDiv.style.maxHeight) {
                targetDiv.style.maxHeight = null;
            } else {
                targetDiv.style.maxHeight = targetDiv.scrollHeight + "px";
            }
        })
    });
}

export function initContentSlider(id) {
    const sliderElement = document.getElementById(id);
    if (!sliderElement) return;

    let amountOfVisible = sliderElement.dataset.amountOfVisible;
    let factLabSlider = new Swiper(`#${id} .swiper`, {
        modules: [Navigation, Pagination],
        slidesPerView: 'auto',
        initialSlide: getInitialSlide(),
        spaceBetween: 2,
        loop: false,
        centeredSlides: true,
        navigation: {
            nextEl: `#${id} .next`,
            prevEl: `#${id} .prev`,
        },

        breakpoints: {
            640: {
                slidesPerView: amountOfVisible,
                centeredSlides: false,
            },
        },
    });


    function getInitialSlide() {
        return window.innerWidth >= 640 ? 0 : 1;
    }

    window.addEventListener('resize', () => {
        const newInitialSlide = getInitialSlide();
        if (factLabSlider.activeIndex !== newInitialSlide) {
            factLabSlider.slideTo(newInitialSlide);
        }
    });
}

export function privacyButtonsHandler() {
    let privacyButtons = document.querySelectorAll('[data-privacy]');
    if (privacyButtons) {
        privacyButtons.forEach(function (link) {
            link.addEventListener('click', function (event) {
                event.preventDefault();

                if (typeof window.__uspapi !== 'undefined') {
                    window.__tcfapi('displayConsentUi', 2, function () {
                    });
                }
            });
        });
    }
}

export function handleAdsVisibility() {
    document.addEventListener('DisableAds', function (event) {
        disableAds();
    });

    document.addEventListener('RefreshAds', function (event) {
        refreshAds();
    });
}

export function disableAds() {
    if (!isGoogleTagReady()) return;
    window.googletag.destroySlots();
}

export function refreshAds() {
    if (!isGoogleTagReady() || typeof window.initSlots !== 'function') return;
    requestManager.adserverRequestSent = false;
    initSlots();
    fetchHeaderBids();
}

function isGoogleTagReady() {
    return window.googletag !== undefined && window.googletag.apiReady === true;
}

export function hidePopupHandler() {
    document.addEventListener('click', (e) => {
        if (null === e.target.closest('[data-close-popup]')) return;

        e.preventDefault();

        closeAllPopups();
        refreshAds();
    });
}

export function sharePopupListener() {
    const popupElement = document.getElementById('sharePopup');
    if (popupElement === null) return;

    document.addEventListener("click", function (event) {
        const target = event.target.closest("[data-modal-share]");
        if (target) {
            event.preventDefault();
            disableAds();

            popupElement.classList.toggle('hidden');
        }
    });
}

export function copyLinkListener() {
    document.addEventListener("click", function (event) {
        const target = event.target.closest("[data-share-copy]");
        if (target) {
            event.preventDefault();
            const defaultSvgFillColor = 'fill-cyan-600';
            const modifiedSvgFillColor = 'fill-white';
            const defaultBackgroundElementColor = 'bg-light-blue-10';
            const modifiedBackgroundElementColor = 'bg-green-700';
            const defaultHoverBgColor = 'group-hover:bg-black';
            const modifiedHoverBgColor = `group-hover:${modifiedBackgroundElementColor}`;
            const backgroundElement = target.querySelector('.transition-colors');
            const svg = backgroundElement.querySelector('svg');
            const isInPopup = event.target.closest('#sharePopup');
            navigator.clipboard.writeText(target.href);

            modifyStyles();

            setTimeout(() => {
                modifyStyles()
            }, 800);

            function modifyStyles() {
                if (!isInPopup) {
                    backgroundElement.classList.toggle(defaultHoverBgColor);
                }

                backgroundElement.classList.toggle(defaultBackgroundElementColor);
                svg.classList.toggle(defaultSvgFillColor);
                svg.classList.toggle(modifiedSvgFillColor);
                backgroundElement.classList.toggle(modifiedHoverBgColor);
                backgroundElement.classList.toggle(modifiedBackgroundElementColor);
            }
        }
    });
}
