"use strict";

import {closeAllPopups, disableAds} from "./general";
import {hideMenu} from "./mobile-menu";

document.addEventListener("DOMContentLoaded", function (event) {
    const createAccountPopupId = 'createAccountPopup';
    const popupElement = document.getElementById(`${createAccountPopupId}`);
    if (popupElement === null) return;

    const profileUrls = {
        'login': '/api/profile/login',
        'verify': '/api/profile/verify',
        'resend': '/api/profile/resend'
    };
    const emailElement = popupElement.querySelector('#email');
    const codeElement = popupElement.querySelector('#code');
    const firstStepElements = popupElement.querySelectorAll('[data-display-on-step1]');
    const secondStepElements = popupElement.querySelectorAll('[data-display-on-step2]');
    const changeEmailElement = document.querySelector(`#${createAccountPopupId} [data-change-email]`);
    const resendCodeElement = document.querySelector(`#${createAccountPopupId} [data-resend-code]`);
    const resendCodeElementTimer = resendCodeElement.querySelector('span');
    let resetCountdownId = undefined;
    let currentStep = 1;

    showCreateAccountPopup();
    submitHandler();
    changeEmailHandler();
    resendCodeHandler();

    function submitHandler() {
        document.querySelector(`#${createAccountPopupId} button`).addEventListener('click', function (event) {
            event.preventDefault();

            let data = {
                'email': emailElement.value,
            };

            if (currentStep === 1) {
                fetch(profileUrls.login, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8',
                    },
                    body: JSON.stringify(data),
                })
                    .then((response) => {
                        if (!response.ok) {
                            return response.json().then(error => {
                                throw new Error(error.message || 'Something went wrong');
                            });
                        }

                        currentStep++;
                        emailElement.parentElement.classList.remove('is-invalid');
                        codeElement.parentElement.classList.remove('is-invalid');
                        showStep();
                    })
                    .catch((error) => {
                        if (emailElement.disabled === false) {
                            emailElement.parentElement.classList.add('is-invalid');
                        }
                    });
            }

            if (currentStep === 2) {
                data.code = codeElement.value;

                fetch(profileUrls.verify, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8',
                    },
                    body: JSON.stringify(data),
                })
                    .then(response => {
                        if (!response.ok) {
                            return response.json().then(error => {
                                throw new Error(error.message || 'Something went wrong');
                            });
                        }
                        return response.json();
                    })
                    .then(data => {
                        window.location.reload();
                    })
                    .catch(error => {
                        if (codeElement) {
                            codeElement.parentElement.querySelector('span').textContent = error.message;
                            codeElement.parentElement.classList.add('is-invalid');
                        }
                    });
            }
        });
    }

    function changeEmailHandler() {
        changeEmailElement.addEventListener('click', function (event) {
            event.preventDefault();

            popupElement.querySelector('form').reset();
            currentStep = 1;
            showStep();

            modifyEmailElement(false);
        });
    }

    function showStep() {
        modifyEmailElement(true);

        firstStepElements.forEach(function (item) {
            item.classList.toggle('hidden');
        });
        secondStepElements.forEach(function (item) {
            item.classList.toggle('hidden');
        });

        initResendTimer();
    }

    function resendCodeHandler() {
        resendCodeElement.addEventListener('click', function (event) {
            event.preventDefault();

            let data = {
                'email': emailElement.value,
            };

            fetch(profileUrls.resend, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8',
                },
                body: JSON.stringify(data),
            })
                .then((response) => {
                    if (!response.ok) {
                        return response.json().then(error => {
                            throw new Error(error.message || 'Something went wrong');
                        });
                    }

                    return response.json();
                })
                .catch(error => {
                    if (codeElement) {
                        codeElement.parentElement.querySelector('span').textContent = error.message;
                        codeElement.parentElement.classList.add('is-invalid');
                    }
                });

            codeElement.value = '';
            initResendTimer();
        });
    }

    function modifyEmailElement(disabled) {
        emailElement.disabled = disabled;
        emailElement.classList.toggle('disabled');
    }

    function showCreateAccountPopup() {
        document.addEventListener("click", function (event) {
            const target = event.target.closest("[data-modal-login]");
            if (target) {
                event.preventDefault();
                closeAllPopups();
                disableAds();
                hideMenu();

                popupElement.classList.toggle('hidden');
            }
        });
    }

    function initResendTimer() {
        clearInterval(resetCountdownId);
        resendCodeElement.disabled = true;
        let timeLeft = 59;
        resendCodeElementTimer.textContent = '01:00';

        resetCountdownId = setInterval(() => {
            const minutes = Math.floor(timeLeft / 60);
            const seconds = timeLeft % 60;

            resendCodeElementTimer.textContent = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
            timeLeft--;

            if (timeLeft < 0) {
                clearInterval(resetCountdownId);
                resendCodeElementTimer.textContent = "";
                resendCodeElement.disabled = false;
            }
        }, 1000);
    }
});
